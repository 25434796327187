import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode } from 'react';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode;
};

export function StyledButton({ className, ...props }: ButtonProps) {
  return <button className={clsx('btn', className)} {...props} />;
}

export function PlainButton({ className, ...props }: ButtonProps) {
  return <button className={clsx('btn btn--default', className)} {...props} />;
}

export function PrimaryButton({ className, ...props }: ButtonProps) {
  return (
    <button
      className={clsx('btn btn--primary gokhos__button2', className)}
      {...props}
    />
  );
}

export function SecondaryButton({ className, ...props }: ButtonProps) {
  return (
    <button
      className={clsx('btn btn--secondary gokhos__button2', className)}
      {...props}
    />
  );
}

export function TertiaryButton({ className, ...props }: ButtonProps) {
  return (
    <button
      className={clsx('btn btn--tertiary gokhos__button2', className)}
      {...props}
    />
  );
}

export function GreyButton({ className, ...props }: ButtonProps) {
  return (
    <button
      className={clsx('btn btn--grey gokhos__button2', className)}
      {...props}
    />
  );
}
